.double-underline {
  position: relative;
  display: inline-block;
}

.double-underline::before,
.double-underline::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px; /* Height of the underline */
  background: yellow; /* Color of the underline */
  left: 0;
}

.double-underline::before {
  bottom: -3px; /* Distance from the text */
}

.double-underline::after {
  bottom: -7px; /* Distance from the first underline */
}
